/* eslint-disable react/style-prop-object */
import React from "react";

function HowToQualify() {
  return (
    <div className="py-[54px] md:py-[108px] lg:px-[196px] bg-[#FFA500] relative">
      {/* <img
        src="https://res.cloudinary.com/ddlupbcws/image/upload/v1681228967/Group_283_p9v0ch.png"
        alt=""
        className="w-[255px] absolute left-[5%] top-[20%] z[-1] hidden lg:block"
      /> */}
      <svg className="w-[255px] absolute left-[5%] top-[20%] z[-1] hidden lg:block" width="155" height="155" viewBox="0 0 155 155" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path style={{ mixBlendMode: "multiply" }} d="M135.203 95.9445C145.639 63.827 128.062 29.3309 95.9445 18.8953C63.827 8.45973 29.3309 26.0364 18.8953 58.1538C8.45973 90.2713 26.0364 124.767 58.1538 135.203C90.2713 145.639 124.767 128.062 135.203 95.9445Z" fill="url(#paint0_radial_204_226)" />
        <path d="M57.7927 27.2957C57.7927 27.2957 62.458 10.4695 76.4539 4.96447C80.0587 3.35417 83.9486 2.47945 87.8957 2.39157C91.8427 2.30369 95.7676 3.00441 99.4405 4.45269C103.113 5.90097 106.46 8.06769 109.285 10.8259C112.11 13.5841 114.356 16.8783 115.891 20.5155C121.583 34.3248 115.891 50.4045 110.977 61.7878C103.869 76.8489 94.8478 90.93 84.1361 103.682C67.7935 100.246 51.9655 94.7048 37.0477 87.1982C26.0687 81.2888 11.824 71.9893 7.84293 57.6202C6.82271 53.8066 6.57375 49.8277 7.11066 45.9167C7.64756 42.0057 8.95953 38.2411 10.9697 34.8435C12.9799 31.4459 15.6478 28.4836 18.8173 26.1301C21.9867 23.7767 25.594 22.0793 29.4277 21.1375C43.7035 16.8766 57.7927 27.2957 57.7927 27.2957Z" fill="#FF4774" />
        <path d="M29.4277 21.0503C25.594 21.992 21.9867 23.6894 18.8173 26.0429C15.6478 28.3964 12.9799 31.3586 10.9697 34.7562C8.95953 38.1538 7.64756 41.9184 7.11066 45.8294C6.57375 49.7404 6.82271 53.7193 7.84293 57.5329C11.824 71.902 26.0687 81.2015 37.0477 87.1109C51.9609 94.6498 67.7892 100.222 84.1361 103.688L57.9482 27.3017C57.9482 27.3017 43.7035 16.8826 29.4277 21.0503Z" fill="#D62D56" />
        <defs>
          <radialGradient id="paint0_radial_204_226" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(76.9572 77.012) rotate(-19.33) scale(61.1465 61.1465)">
            <stop offset="0.31" stop-color="#FF8C9E" />
            <stop offset="0.38" stop-color="#FF9CAB" />
            <stop offset="0.57" stop-color="#FFC7CF" />
            <stop offset="0.75" stop-color="#FFE5E9" />
            <stop offset="0.9" stop-color="#FFF8F9" />
            <stop offset="1" stop-color="white" />
          </radialGradient>
        </defs>
      </svg>

      <div className="relative md:h-[621px] gap-3 flex flex-col items-center justify-center">
        <img
          src="https://res.cloudinary.com/ddlupbcws/image/upload/v1681228966/Rectangle_1439_ucqptk.png"
          alt=""
          className="w-[95%] md:w-[100%] md:h-[386px] h-[300px]"
        />
        <div className="absolute left-[10%]">
          <h1 className="md:text-[50px] text-[25px] font-semibold text-center font-plus -rotate-3 mb-2 text-[#FFA500]">
            How to qualify
          </h1>
          <ul className="font-plus font-normal flex flex-col lg:gap-5 gap-3 text-[15px] w-[90%] md:text-[27px] -rotate-3 md:w-[80%]">
            <li>
              1) To qualify, simply purchase an FT9ja Zuma Account Size of $25,000 or higher between now February 2nd (6 PM) and February 12th (6 PM). This will make you eligible for the offer
            </li>
            <li>
              2) Simply click the 'Join Promo' button and upload your payment receipt to enter.
            </li>
          </ul>
        </div>
      </div>
      {/* <img
        src="https://res.cloudinary.com/ddlupbcws/image/upload/v1681228966/Group_284_n481ah.png"
        alt=""
        className="w-[112px] absolute bottom-[20%] right-[8%] hidden lg:block"
      /> */}
      <svg className="w-[112px] absolute bottom-[20%] right-[8%] hidden lg:block" width="155" height="155" viewBox="0 0 155 155" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path style={{ mixBlendMode: "multiply" }} d="M135.203 95.9445C145.639 63.827 128.062 29.3309 95.9445 18.8953C63.827 8.45973 29.3309 26.0364 18.8953 58.1538C8.45973 90.2713 26.0364 124.767 58.1538 135.203C90.2713 145.639 124.767 128.062 135.203 95.9445Z" fill="url(#paint0_radial_204_226)" />
        <path d="M57.7927 27.2957C57.7927 27.2957 62.458 10.4695 76.4539 4.96447C80.0587 3.35417 83.9486 2.47945 87.8957 2.39157C91.8427 2.30369 95.7676 3.00441 99.4405 4.45269C103.113 5.90097 106.46 8.06769 109.285 10.8259C112.11 13.5841 114.356 16.8783 115.891 20.5155C121.583 34.3248 115.891 50.4045 110.977 61.7878C103.869 76.8489 94.8478 90.93 84.1361 103.682C67.7935 100.246 51.9655 94.7048 37.0477 87.1982C26.0687 81.2888 11.824 71.9893 7.84293 57.6202C6.82271 53.8066 6.57375 49.8277 7.11066 45.9167C7.64756 42.0057 8.95953 38.2411 10.9697 34.8435C12.9799 31.4459 15.6478 28.4836 18.8173 26.1301C21.9867 23.7767 25.594 22.0793 29.4277 21.1375C43.7035 16.8766 57.7927 27.2957 57.7927 27.2957Z" fill="#FF4774" />
        <path d="M29.4277 21.0503C25.594 21.992 21.9867 23.6894 18.8173 26.0429C15.6478 28.3964 12.9799 31.3586 10.9697 34.7562C8.95953 38.1538 7.64756 41.9184 7.11066 45.8294C6.57375 49.7404 6.82271 53.7193 7.84293 57.5329C11.824 71.902 26.0687 81.2015 37.0477 87.1109C51.9609 94.6498 67.7892 100.222 84.1361 103.688L57.9482 27.3017C57.9482 27.3017 43.7035 16.8826 29.4277 21.0503Z" fill="#D62D56" />
        <defs>
          <radialGradient id="paint0_radial_204_226" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(76.9572 77.012) rotate(-19.33) scale(61.1465 61.1465)">
            <stop offset="0.31" stop-color="#FF8C9E" />
            <stop offset="0.38" stop-color="#FF9CAB" />
            <stop offset="0.57" stop-color="#FFC7CF" />
            <stop offset="0.75" stop-color="#FFE5E9" />
            <stop offset="0.9" stop-color="#FFF8F9" />
            <stop offset="1" stop-color="white" />
          </radialGradient>
        </defs>
      </svg>
    </div>
  );
}

export default HowToQualify;
