import React from "react";
import Right from "../components/Right";
import Left from "../components/Left";

function Participants() {
  return (
    <div className="flex md:flex-row flex-col-reverse items-center md:items-start justify-center py-16 gap-[103px]">
      <Left />
      <Right />
    </div>
  );
}

export default Participants;
