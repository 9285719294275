import React, { useState } from "react";
import { storage } from "../firebase";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { Line } from "rc-progress";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import ReactLoading from "react-loading";
import { v4 as uuidv4 } from "uuid";

function Left() {
  const navigate = useNavigate();
  const [imgUrl, setImgUrl] = useState("");
  const [participantInfo, setParticippantInfo] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    nationality: "",
  });
  const [progress, setProgress] = useState(null);
  const [sending, setSending] = useState(false);
  // console.log(participantInfo);
  const id = uuidv4();

  const handleChange = async (e) => {
    const file = e.target.files[0];

    try {
      const storageRef = ref(storage, `images/${id}`);

      const uploadTask = uploadBytesResumable(storageRef, file);

      // Register three observers:
      // 1. 'state_changed' observer, called any time the state changes
      // 2. Error observer, called on failure
      // 3. Completion observer, called on successful completion
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + progress + "% done");
          setProgress(progress);
          switch (snapshot.state) {
            case "paused":
              console.log("Upload is paused");
              break;
            case "running":
              console.log("Upload is running");
              break;
            default:
              console.log("No upload yet");
          }
        },
        (error) => {
          // Handle unsuccessful uploads
          console.log(error);
        },
        () => {
          // Handle successful uploads on complete
          // For instance, get the download URL: https://firebasestorage.googleapis.com/...
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            setImgUrl(downloadURL);
            console.log("File available at", downloadURL);
          });
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSending(true);

    const proofOfAdvocacy = imgUrl;
    const firstName = participantInfo.firstName;
    const lastName = participantInfo.lastName;
    const email = participantInfo.email;
    const phoneNumber = participantInfo.phoneNumber;
    const nationality = participantInfo.nationality;

    const post = {
      proofOfAdvocacy,
      firstName,
      lastName,
      email,
      phoneNumber,
      nationality,
    };

    try {
      await axios.post(
        "https://ft9ja-at-2-promo-api.vercel.app/api/promo",
        post
      );

      setParticippantInfo({
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        nationality: "",
      });
      setImgUrl("");
      setProgress(null);
      navigate("/success");
    } catch (error) {
      console.log(error);
      alert(
        "Please ensure that you have uploaded your proof of advocacy and that you have fill out the form below..."
      );
      window.location.reload();
    }
  };

  return (
    <div className="md:w-[55%] w-[95%] shadow-[0_0_4px_rgba(0,0,0,0.25)] flex justify-center">
      <div className="my-16 flex flex-col  w-[100%]">
        <span className="text-left font-inter font-bold text-2xl lg:ml-10 ml-2 mb-10">
          Step 1
        </span>
        <h1 className="font-open font-bold text-xl text-center">
          Attach receipt of purchased account
        </h1>
        <input
          type="file"
          onChange={handleChange}
          id="upload"
          className="hidden"
        />
        <label
          htmlFor="upload"
          className="md:w-[70%] w-[90%] shadow-[0_0_4px_rgba(0,0,0,0.25)] mt-[46px] mx-auto flex flex-col items-start px-8 py-6"
        >
          <p className="font-open font-bold text-lg mb-2">Upload receipt</p>
          {/* <p className="font-open text-xs font-semibold text-gray-500 mb-4">
            Upload and attach proof of advocacy
          </p> */}
          <div className="border border-black border-dashed flex flex-col items-center w-[100%] py-8">
            <img
              src="https://res.cloudinary.com/ddlupbcws/image/upload/v1681333583/document-text_vu4th0.png"
              alt=""
              className="w-[87px] mb-8"
            />
            <p className="mb-3 font-open font-semibold text-sm text-gray-500">
              <span className="font-bold text-black">Click to Upload </span>or
              drag and drop
            </p>
            <p className="font-open text-gray-500 font-semibold text-xs">
              Maximum file size of 50mb
            </p>
          </div>
        </label>
        {progress && (
          <div className="mx-auto flex items-center gap-2 justify-center mt-5">
            <Line
              percent={progress}
              strokeWidth={2}
              strokeColor="#359602"
              className="md:w-[100%] w-[80%]"
            />
            <span>{Math.floor(progress) + "%"}</span>
          </div>
        )}
        <hr className="h-[1px] w-[80%] bg-[#D4D4D4] my-[70px] mx-auto" />
        <span className="text-left font-inter font-bold text-2xl lg:ml-10 ml-2 mb-10">
          Step 2
        </span>
        <form
          onSubmit={(e) => handleSubmit(e)}
          className="w-[80%] flex flex-col gap-9 items-center mx-auto"
        >
          <h1 className="font-open font-bold text-xl mb-9 text-center">
            Input your information to join the promo
          </h1>
          <div className="flex md:flex-row flex-col items-center gap-10">
            <div className="flex flex-col">
              <label
                htmlFor=""
                className="font-plus font-medium text-base mb-4"
              >
                First Name
              </label>
              <input
                type="text"
                value={participantInfo.firstName}
                onChange={(e) =>
                  setParticippantInfo({
                    ...participantInfo,
                    firstName: e.target.value,
                  })
                }
                className="border outline-none bg-[#E9E9E9] font-plus font-bold text-lg py-3 w-[303px] px-3 rounded-[10px]"
              />
            </div>
            <div className="flex flex-col">
              <label
                htmlFor=""
                className="font-plus font-medium text-base mb-4"
              >
                Last Name
              </label>
              <input
                type="text"
                value={participantInfo.lastName}
                onChange={(e) =>
                  setParticippantInfo({
                    ...participantInfo,
                    lastName: e.target.value,
                  })
                }
                className="border outline-none bg-[#E9E9E9] font-plus font-bold text-lg py-3 w-[303px] px-3 rounded-[10px]"
              />
            </div>
          </div>
          <div className="flex items-center gap-10 md:flex-row flex-col">
            <div className="flex flex-col">
              <label
                htmlFor=""
                className="font-plus font-medium text-base mb-4"
              >
                Email
              </label>
              <input
                type="text"
                value={participantInfo.email}
                onChange={(e) =>
                  setParticippantInfo({
                    ...participantInfo,
                    email: e.target.value,
                  })
                }
                className="border outline-none bg-[#E9E9E9] py-3 w-[303px] font-plus font-bold text-lg px-3 rounded-[10px]"
              />
            </div>
            <div className="flex flex-col">
              <label
                htmlFor=""
                className="font-plus font-medium text-base mb-4"
              >
                Phone number
              </label>
              <input
                type="tel"
                value={participantInfo.phoneNumber}
                onChange={(e) =>
                  setParticippantInfo({
                    ...participantInfo,
                    phoneNumber: e.target.value,
                  })
                }
                className="border outline-none bg-[#E9E9E9] py-3 w-[303px] font-plus font-bold text-lg px-3 rounded-[10px]"
              />
            </div>
          </div>
          <div className="flex items-end gap-10 md:flex-row flex-col ">
            <div className="flex flex-col">
              <label
                htmlFor=""
                className="font-plus font-medium text-base mb-4"
              >
                Nationality
              </label>
              <input
                type="text"
                value={participantInfo.nationality}
                onChange={(e) =>
                  setParticippantInfo({
                    ...participantInfo,
                    nationality: e.target.value,
                  })
                }
                className="border outline-none bg-[#E9E9E9] py-3 w-[303px] px-3 rounded-[10px] font-plus font-bold text-lg"
              />
            </div>
            <div>
              {sending ? (
                <div className="w-[303px] flex items-center gap-5">
                  {" "}
                  <span className="text-2xl">Sending...</span>
                  <ReactLoading type="spin" color="#359602" />
                </div>
              ) : (
                <button className="border outline-none bg-[#359602] text-white font-plus font-bold text-lg py-3 w-[303px] px-3 rounded-[10px]">
                  Submit
                </button>
              )}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Left;
