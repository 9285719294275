import React from "react";
import Navbar from "../components/Navbar";
import Hero from "../components/Hero";
import Terms from "../components/Terms";
import HowToQualify from "../components/HowToQualify";
import Footer from "../components/Footer";

function Home() {
  return (
    <div>
      <Navbar />
      <Hero />
      <HowToQualify />
      <Terms />
      <Footer />
    </div>
  );
}

export default Home;
