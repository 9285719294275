import React from "react";

function Header() {
  return (
    <div className="bg-[#359602] w-full relative flex h-max items-center justify-center mt-[52px]">
      <img
        src="https://res.cloudinary.com/ddlupbcws/image/upload/v1680278046/patternpad_zxi7q9.png"
        alt=""
        className="w-full sm:h-full h-[10vh]"
      />
      <h1 className="sm:text-4xl text-3xl font-pop mb-10 sm:top-[40%] top-8 absolute font-bold text-white">
        Promo App
      </h1>
    </div>
  );
}

export default Header;
