import React from "react";
import { Link } from "react-router-dom";

function Hero() {
  return (
    <div className='bg-[url("https://res.cloudinary.com/ddlupbcws/image/upload/v1681224840/Vector_6_f1riwc.png")] relative pb-[146px] bg-no-repeat bg-[length:100%_100%] md:bg-cover flex items-center justify-center'>
      <img
        src="https://s3-alpha-sig.figma.com/img/7cb3/3206/6d4c38eb5d86acbee8a5d94dc7b9dea5?Expires=1707696000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=U-M-nr69urL6c0yAbEUaTFlEY52VahtEcqL-C-vaF7bg0Wy9cZscOTqVFJCjlr-hQP1-UVaUDABx1tNdP4NRkhgLDh~XBtcGPKZotAoekDs0f2gMatj8zwIdesW1Os-0wBIF5qB-0GnMC8cQ-GbJrWTkZTHeUx14D481uPE~ZwbEgpwpfJ8XGxN1ndPCz1ps3FeGxrR7JTuRt4IgWKBWmPEEW1JJmreDBAPh2v94Bynx-9su2ww7jyqg64rD-6sEOfOS-zgd~H1XUepOdoJXkdrpQpaSoY7A1oFo7aJBSdy5lRRnPLll8yCk9oHNcex4xaeWc4OBE2ab3p9ENl8cMQ__"
        alt=""
        className=" w-[101px] md:w-[202px] md:bottom-[50%] bottom-[25%] absolute left-[8%]"
      />
      <img
        src="https://lh3.googleusercontent.com/p/AF1QipMqV1_t0Ag99JAg9ge2sSWhTTj1s8XqdjXlNM68=s1360-w1360-h1020"
        alt=""
        className="w-[121.5px] md:w-[243px] absolute top-[8%] right-[7%]"
      />
      <div className="flex flex-col items-center gap-5 mt-[159px]">
        <h1 className="font-plus font-extrabold md:text-[40px] text-[30px]">
          Join the FT9ja{" "}
        </h1>
        <h1 className="font-sat md:text-[80px] text-[40px]">Valentine's Day</h1>
        <div className="flex gap-2 items-center text-[85px] font-extrabold text-[#FFA500]">
          <img
            src="https://res.cloudinary.com/ddlupbcws/image/upload/v1681224839/Star_7_yt81m2.png"
            alt=""
            className="w-[30px]"
          />
          <h1 className="font-plus text-[50px] md:text-[85px]">PROMO</h1>
          <img
            src="https://res.cloudinary.com/ddlupbcws/image/upload/v1681224839/Star_7_yt81m2.png"
            alt=""
            className="w-[30px]"
          />
        </div>
        <div className="bg-[#FFA500] lg:w-[70%] w-[90%] py-3 rounded-2xl lg:mt-5 z-10">
          <h1 className="text-white font-sat lg:text-[40px] text-[22px] text-center">
            Win an all-expense paid trip with your Val/loved-one to {" "}
            <span className="text-black underline">Zuma Resort</span> at Zuma Rock.
          </h1>
        </div>
        <Link to="/promo" className="z-10">
          <button className="w-[165px] h-[58px] bg-[#359602] font-plus font-bold text-[18px] rounded-[5px] text-white mt-[140px]">
            Join Promo
          </button>
        </Link>
      </div>
      <p className="flex items-center absolute bottom-[10%] left-[8%]">
        Scroll down for more information{" "}
        <span>
          <img
            src="https://res.cloudinary.com/ddlupbcws/image/upload/v1681228406/arrow-down_ofyabb.png"
            alt=""
            className="w-[17px]"
          />
        </span>
      </p>
      <img
        src="https://lh3.googleusercontent.com/p/AF1QipNgKJV0sBJhyS_q4ins7P7KXl4qkE7aWxPH7yI1=s1360-w1360-h1020"
        alt=""
        className="w-[120px] md:w-[168px] absolute bottom-[30%] md:bottom-[20%] right-[7%]"
      />
    </div>
  );
}

export default Hero;
