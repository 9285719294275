import React from "react";
import { Link } from "react-router-dom";

function Navbar() {
  return (
    <div className="px-2 md:px-[102px] py-[20px] shadow-[0_5px_1px_rgba(0,0,0,0.1)]">
      <div className="flex justify-between items-center">
        <Link to="/">
          <img
            src="https://res.cloudinary.com/ddlupbcws/image/upload/v1680791936/logo_2_ft9ja_1_fsh9iz.png"
            alt=""
            className="md:w-[130px] w-[80px]"
          />
        </Link>
        <Link to="/promo">
          <button className="md:w-[165px] w-[120px] md:h-[58px] h-[45px] bg-[#359602] text-white rounded-[5px] text-[18px] font-bold">
            Join Promo
          </button>
        </Link>
      </div>
    </div>
  );
}

export default Navbar;
