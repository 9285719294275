import React from "react";

function Footer() {
  return (
    <div className="lg:px-[130px] flex flex-col items-center gap-[124px] pb-3">
      <div className="flex lg:flex-row flex-col gap-7 items-start justify-between border-t border-[#D4D4D4]">
        <div className="lg:w-[30%] flex flex-col gap-[20px] mt-10">
          <img
            src="https://res.cloudinary.com/ddlupbcws/image/upload/v1680791936/logo_2_ft9ja_1_fsh9iz.png"
            alt=""
            className="w-[116px] pl-1"
          />
          <p className="pl-3 font-open text-[15px] font-normal">
            We are Nigeria's 1st next-generational proprietary trading firm. Our
            aim is to scout for talented but undercapitalized Financial-asset
            Traders in Nigeria (FT9ja) and empower them.{" "}
          </p>
        </div>
        <div className="flex gap-3 pl-3 flex-col md:gap-[20px] md:mt-10">
          <h1 className="font-open font-bold text-[20px]">HELP</h1>
          <p className="font-open text-[15px] font-normal">
            <a
              href="https://ft9ja.com/how-it-works"
              target="_blank"
              rel="noreferrer"
            >
              How it Works
            </a>
          </p>
          <p className="font-open text-[15px] font-normal">
            <a
              href="https://ft9ja.com/terms"
              target="_blank"
              rel="noreferrer"
            >
              Terms & Condition
            </a>
          </p>
          <p className="font-open text-[15px] font-normal">
            <a
              href="https://www.ft9ja.com/faq/"
              target="_blank"
              rel="noreferrer"
            >
              FAQ
            </a>
          </p>
        </div>
        <div className="flex pl-3 gap-3 flex-col md:gap-[20px] md:mt-10">
          <h1 className="font-open font-bold text-[20px]">COMPANY</h1>
          <p className="font-open text-[15px] font-normal">Careers</p>
          <p className="font-open text-[15px] font-normal">
            <a
              href="https://www.community.ft9ja.com/blog"
              target="_blank"
              rel="noreferrer"
            >
              Blogs
            </a>
          </p>
        </div>
        <div className="flex pl-3 flex-col gap-3 md:gap-[20px] md:mt-10">
          <h1 className="font-open font-bold text-[20px]">CONTACT</h1>
          <p className="font-open text-[15px] font-normal">+2348138462394</p>
          <p className="font-open text-[15px] font-normal">promo@ft9ja.com</p>
          <p className="font-open text-[15px] font-normal">
            Mon to Fri - 9am to 5pm WAT
          </p>
        </div>
      </div>
      <p className="font-inter font-normal text-sm text-[#808080] flex items-center gap-1">
        <span className="text-lg">&copy;</span> Copyright 2024 FT9ja. All rights
        reserved.
      </p>
    </div>
  );
}

export default Footer;
